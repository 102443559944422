<template>
  <div>
    <el-affix>
      <nav-bar></nav-bar>
    </el-affix>
    <tool-box></tool-box>

    <div class="container">
      <el-card class="box-card">
        <div class="study-con">
          <div class="menu">
            <el-menu :default-active="this.$route.path" router>
              <el-menu-item :index="item.path" v-for="(item, index) in navList" :key="index">
                <svg-icon :icon-class="item.icon" class="nav-icon"></svg-icon>
                <template #title>{{ item.navItem }}</template>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="con">
            <router-view></router-view>
          </div>
        </div>
      </el-card>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"
import SvgIcon from "@/components/svgicon"

export default {
  components: {
    NavBar,
    ToolBox,
    WebFooter,
    SvgIcon,
  },
  data() {
    return {
      navList: [
        { path: "/user/mycourse", navItem: "我的课程", icon: "kecheng-" },
        { path: "/user/myorder", navItem: "我的订单", icon: "wodedingdan" },
        { path: "/user/myexam", navItem: "我的题库", icon: "icon_tikubianxie-01" },
        { path: "/user/mycollect", navItem: "我的收藏", icon: "shoucang" },
        { path: "/user/mywrong", navItem: "我的错题", icon: "bianji1" },
        { path: "/user/myinfo", navItem: "个人信息", icon: "gerenxinxiicon" },
        { path: "/user/myquestion", navItem: "答疑服务", icon: "dayi" },
      ],
    }
  },
  created() {

  },
  methods: {

  },
}
</script>

<style scoped lang="scss">
.box-card {
  margin: 20px 0px;
}
.study-con {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .el-menu {
    .el-menu-item {
      display: flex;
      align-items: center;
    }
    .el-menu-item.is-active {
      color: #fff;
      background-color: $theme-color;
    }
  }
  .con {
    flex: 1;
    padding: 10px 15px;
  }
}
.nav-icon {
  font-size: 22px;
  margin-right: 5px;
}
</style>
